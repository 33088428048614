import React from 'react';
import SignOut from './SignOut';
import '../App.css';

const SafeguardLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="102"
    height="40"
    viewBox="0 0 102 40"
    fill="none"
  >
    <path
      d="M2.14238 12.3744C3.27113 13.6095 4.83761 14.4405 7.026 14.4405C9.23743 14.4405 10.5965 13.5872 10.5965 12.1049C10.5965 11.0268 9.88246 10.6002 8.96101 10.2858C7.57879 9.81418 5.68994 9.612 3.96217 8.93832C2.25752 8.26463 1.10579 7.29899 1.10579 5.41246C1.10579 2.82976 3.22505 1.01074 6.95681 1.01074C10.0897 1.01074 12.0017 2.29075 12.9692 3.48104L10.9651 5.18788C10.0206 4.22224 8.70762 3.50352 6.81863 3.50352C4.99876 3.50352 3.87001 4.17735 3.87001 5.27765C3.87001 6.08615 4.49199 6.53539 5.27519 6.82734C6.6343 7.34388 8.73059 7.50103 10.3661 8.12996C12.0247 8.75876 13.3608 9.88169 13.3608 12.0824C13.3608 15.249 10.6195 16.8885 6.8416 16.8885C3.59358 16.8885 1.28998 15.6532 0 14.0812L2.14238 12.3744Z"
      fill="black"
    />
    <path
      d="M15.5539 5.682C16.4753 5.03058 17.9267 4.40186 19.9998 4.40186C22.8333 4.40186 24.8374 5.54733 24.8374 9.14049V16.7087H22.3725V15.9678C21.405 16.5517 20.2302 16.866 18.9862 16.866C16.5444 16.866 14.6094 15.6532 14.6094 13.0256C14.6094 10.3756 16.5674 8.9832 19.2857 8.9832C20.2762 8.9832 21.3129 9.1629 22.2112 9.52222C22.2112 7.34387 21.2898 6.84974 19.7233 6.84974C18.4334 6.84974 17.4428 7.18659 16.5905 7.74802L15.5538 5.68193L15.5539 5.682ZM22.2113 14.216V11.2066C21.4972 11.0046 20.6678 10.8697 19.9307 10.8697C18.3643 10.8697 17.0973 11.4536 17.0973 12.8685C17.0973 14.216 18.2491 14.7324 19.6083 14.7324C20.4606 14.7324 21.405 14.5305 22.2113 14.216Z"
      fill="black"
    />
    <path
      d="M32.8291 2.29587C32.5177 2.22668 31.9821 2.15601 31.5444 2.15601C30.8764 2.15601 30.3465 2.42548 30.3465 3.32389V4.69367H33.1869L32.2904 6.80486H30.3465V16.7087H27.6974V6.80486H25.5182V4.69367H27.6974V2.89706C27.6974 0.583908 29.0105 0 30.8073 0C31.6827 0 32.1716 0.0721455 32.9159 0.173233L32.8291 2.29587Z"
      fill="black"
    />
    <path
      d="M35.5025 11.5209C35.7788 13.7219 37.1149 14.6651 38.5432 14.6651C39.6258 14.6651 40.432 14.126 40.9389 13.3851L42.7127 14.8897C42.0216 15.788 40.6395 17.0007 38.4049 17.0007C35.4563 17.0007 33.0145 14.8672 33.0145 10.6227C33.0145 6.62516 35.203 4.40186 38.2667 4.40186C41.3304 4.40186 43.5419 6.62516 43.5419 10.6676C43.5419 11.0719 43.5189 11.2515 43.4959 11.5209H35.5025ZM35.5025 9.63455H41.031C40.7546 7.72554 39.695 6.73728 38.2667 6.73728C36.8155 6.73728 35.7788 7.77043 35.5025 9.63455Z"
      fill="black"
    />
    <path
      d="M55.4145 4.69381V14.3732C55.4145 18.7301 53.3008 20.6619 48.0921 21.4493L48.1138 19.1001C51.5487 18.4209 52.9266 17.3151 52.9266 15.0471V14.6427C52.1204 15.4961 50.9686 16.1249 49.4252 16.1249C46.0619 16.1249 44.7949 13.1156 44.7949 10.2634C44.7949 7.41124 46.0619 4.40186 49.4252 4.40186C50.9687 4.40186 52.1204 5.0532 52.9266 5.92906V4.69374L55.4145 4.69381ZM52.9266 12.1725V8.44432C52.3507 7.45627 51.4523 6.73749 50.0932 6.73749C48.02 6.73749 47.3289 8.42205 47.3289 10.3084C47.3289 12.1725 48.02 13.8793 50.0932 13.8793C51.4753 13.8793 52.3507 13.1381 52.9266 12.1725Z"
      fill="black"
    />
    <path
      d="M60.4152 4.69385V12.2623C60.4152 13.8794 61.4289 14.5531 62.7879 14.5531C63.8476 14.5531 64.7691 14.1713 65.391 12.7787V4.69385H68.0631V16.7089H65.5523V15.4513C64.6538 16.4843 63.4329 17.0009 61.8895 17.0009C59.4247 17.0009 57.743 15.6534 57.743 12.7114V4.69392H60.4152L60.4152 4.69385Z"
      fill="black"
    />
    <path
      d="M70.7494 5.682C71.6708 5.03058 73.122 4.40186 75.1953 4.40186C78.0287 4.40186 80.0328 5.54733 80.0328 9.14049V16.7087H77.568V15.9678C76.6004 16.5517 75.4256 16.866 74.1817 16.866C71.7399 16.866 69.8049 15.6532 69.8049 13.0256C69.8049 10.3756 71.7629 8.9832 74.4811 8.9832C75.4717 8.9832 76.5083 9.1629 77.4066 9.52222C77.4066 7.34387 76.4852 6.84974 74.9188 6.84974C73.6288 6.84974 72.6382 7.18659 71.7859 7.74802L70.7494 5.682ZM77.4066 14.216V11.2066C76.6926 11.0046 75.8633 10.8697 75.1261 10.8697C73.5597 10.8697 72.2927 11.4536 72.2927 12.8685C72.2927 14.216 73.4445 14.7324 74.8036 14.7324C75.6559 14.7324 76.6003 14.5305 77.4066 14.216Z"
      fill="black"
    />
    <path
      d="M89.2725 7.41124C88.8578 7.0071 88.3049 6.84982 87.6139 6.84982C86.5773 6.84982 85.6558 7.23155 85.0338 8.64643V16.7088H82.3616V4.69374H84.8726V5.92906C85.702 4.91839 86.8076 4.40186 88.1207 4.40186C89.2034 4.40186 89.8714 4.76125 90.263 5.1205L89.2725 7.41124Z"
      fill="black"
    />
    <path
      d="M101.158 0.112305V16.7087H98.6705V15.3612C97.8643 16.3046 96.7124 17.0007 95.1691 17.0007C91.8058 17.0007 90.5389 13.7892 90.5389 10.6899C90.5389 7.61312 91.8059 4.40177 95.1461 4.40177C96.5974 4.40177 97.7262 5.00822 98.5094 5.86139V0.988166L101.158 0.112305ZM98.5093 12.9807V8.42189C97.9334 7.47852 97.0811 6.78243 95.8141 6.78243C93.7638 6.78243 93.0728 8.60145 93.0728 10.69C93.0728 12.8012 93.7638 14.6202 95.8371 14.6202C97.0811 14.6202 97.9334 13.9465 98.5093 12.9808V12.9807Z"
      fill="black"
    />
    <path
      d="M70.2011 25.4166C70.2011 28.7139 67.7497 30.7055 63.7092 30.7055H59.4417V35.5963H57.7619V20.1055H63.7092C67.7496 20.1055 70.2011 22.097 70.2011 25.4166ZM68.5213 25.4166C68.5213 22.9381 66.8416 21.5218 63.6637 21.5218H59.4416V29.2671H63.6637C66.8416 29.2671 68.5213 27.8508 68.5213 25.4166Z"
      fill="#6B19E5"
    />
    <path
      d="M83.9499 23.9558V35.5961H82.4064V33.2946C81.4303 34.8658 79.7732 35.7066 77.7985 35.7066C74.3935 35.7066 71.8513 33.2946 71.8513 29.7759C71.8513 26.2572 74.3936 23.8672 77.7985 23.8672C79.7052 23.8672 81.3395 24.6639 82.3382 26.1687V23.9557H83.9499V23.9558ZM82.3609 29.776C82.3609 27.054 80.4541 25.2394 77.9346 25.2394C75.3922 25.2394 73.4856 27.054 73.4856 29.776C73.4856 32.498 75.3923 34.3125 77.9346 34.3125C80.4541 34.3125 82.3609 32.498 82.3609 29.776Z"
      fill="#6B19E5"
    />
    <path
      d="M96.5298 23.9556L92.0353 33.8476L87.5409 23.9556H85.8611L91.1954 35.5738L90.6733 36.7023C89.9923 38.163 89.266 38.6497 88.199 38.6497C87.5527 38.6497 86.9829 38.4757 86.4895 38.1465L85.9329 39.4333C86.581 39.8093 87.3658 39.9998 88.1764 39.9998C89.8788 39.9998 91.15 39.2693 92.1487 36.9901L98.1186 23.9556H96.5296H96.5298Z"
      fill="#6B19E5"
    />
  </svg>
);

export function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark sg-header">
      <div className="container-fluid">
        <a className="navbar-brand" href="#/">
          <SafeguardLogo />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 small">
            <li className="nav-item">
              <span
                className="nav-link active page-header"
                id="homePage"
                aria-current="page"
              >
                Bank Data App
              </span>
            </li>
          </ul>
          <SignOut />
        </div>
      </div>
    </nav>
  );
}
