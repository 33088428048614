import React, { useEffect, useState } from 'react';
// import { s3DeleteFolder } from '../aws/s3Helper';
import { useAuth0 } from '@auth0/auth0-react';
import { PASSCODE } from '../constants';
import SignOut from '../components/SignOut';

// type NullableFile = File | null;
// const DEFAULT_FILE: NullableFile = null;
type NullableFileFolder = FileList | null;
const DEFAULT_FILES_FOLDER: NullableFileFolder = null;

function E2E() {
  const [, setAuth0Token] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  /* State variables */
  // const [file, setFile] = useState(DEFAULT_FILE);
  const [folder, setFolder] = useState(DEFAULT_FILES_FOLDER);
  const [sourceFolderName, setSourceFolderName] = useState('');
  const [unityRootPath, setUnityRootPath] = useState('test_data/unity/');
  const [inputUploaded, setInputUploaded] = useState({
    key: null,
    error: null,
  });
  const [unityFolderUploaded, setUnityFolderUploaded] = useState([]);
  const [passcode, setPasscode] = useState('');

  /* Effect hooks */

  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="navbar-brand" href="#">
            Safeguard Pay
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" id="homePage" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/admin">
                  Admin
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="/admin/e2e"
                >
                  AdminE2E
                </a>
              </li>
            </ul>
            <SignOut />
          </div>
        </div>
      </nav>
      <div className="container-fluid p-4 mt-2">
        {passcode !== PASSCODE ? (
          <div className="input-group mb-3">
            <span className="input-group-text">Passcode:</span>
            <input
              type="password"
              className="form-control"
              placeholder="Enter Admin's Passcode"
              aria-label="Passcode"
              aria-describedby="basic-addon1"
              onChange={async e => {
                setPasscode(e.target.value.toString());
              }}
            />
          </div>
        ) : null}
        {passcode === PASSCODE ? (
          <div className="container-fluid p-4 col-8">
            <h5>Upload E2E Test Input File:</h5>
            <div className="container-fluid bg-light border p-5">
              <input
                className="form-control"
                id="e2eFileUploadInput1"
                type="file"
                onChange={e => {
                  setInputUploaded({ key: null, error: null });
                  // setFile(e.target.files[0]);
                }}
              />
              <button
                className="btn btn-primary btn-block mt-2"
                onClick={async () => {
                  console.warn('THIS WILL BE REWRITTEN');
                  // const uploadParams = {
                  //   folder: 'test_data/e2e',
                  //   file: file,
                  // };
                  // const uploadedFile = await s3Helper.uploadObject(
                  //   uploadParams,
                  // );
                  // if (uploadedFile.params) {
                  //   console.log(uploadedFile);
                  //   setInputUploaded({
                  //     key: uploadedFile.params.Key,
                  //     error: null,
                  //   });
                  // } else {
                  //   setInputUploaded({
                  //     key: null,
                  //     error: uploadedFile,
                  //   });
                  // }
                }}
              >
                Upload to S3
              </button>

              <div>
                {inputUploaded.key && !inputUploaded.error && (
                  <div className="form-text text-success">
                    Your file is uploaded to <i>{inputUploaded.key}</i>
                  </div>
                )}
                {!inputUploaded.key && inputUploaded.error && (
                  <div className="form-text text-danger">
                    Error: {inputUploaded.error.message}
                  </div>
                )}
                {!inputUploaded.key && !inputUploaded.error && (
                  <div className="form-text">
                    Upload <i>e2eInput.csv</i> file.
                  </div>
                )}
              </div>
            </div>

            <br />
            <h5>Upload Unity files:</h5>
            <p>
              We consider the Unity-generated bank files as the source of truth.
              you can build the folder structure locally and use this section to
              upload the whole folder to S3, e.g. the <code>payrolls</code>{' '}
              folder can be uploaded to: <code>s3:test_data/unity</code>
              <br />
              You do <b>NOT</b> need to create the <code>test_data/unity</code>{' '}
              folder itself . You can also upload specific subfolders if you
              provide the right right destination path.
            </p>
            <div className="container-fluid bg-light border p-5">
              <label className="form-label fw-bold">Source Folder:</label>
              <input
                className="form-control"
                id="e2eFolderUploadInput1"
                type="file"
                onChange={e => {
                  setUnityFolderUploaded([]);
                  setFolder(e.target.files);
                  // Get foldername of the first file
                  setSourceFolderName(
                    e.target.files[0].webkitRelativePath.split('/')[0],
                  );
                }}
                /* @ts-expect-error */
                webkitdirectory="true"
                mozdirectory="true"
              />
              {folder && Object.keys(folder).length > 0 ? (
                <div>
                  <br />
                  <label className="form-label fw-bold">
                    Destination Folder:
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text  bg-success text-light">
                        s3:test_data/unity/
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => {
                        const rootPath =
                          'test_data/unity/' +
                          (e.target.value ? e.target.value + '/' : '');
                        setUnityRootPath(rootPath);
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-success text-light">
                        /
                      </span>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-block mt-2"
                    data-bs-toggle="modal"
                    data-bs-target="#unityFolderUploadModal"
                  >
                    Upload folder to S3
                  </button>
                  {/* Confirmation modal */}
                  <div
                    className="modal fade"
                    id="unityFolderUploadModal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="unityFolderUploadModalTitle"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="unityFolderUploadModalTitle"
                          >
                            <b className="text-danger">WARNING! </b>
                            <br />
                            Replace an existing folder
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            id="bfRunModalCloseButton"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          className="modal-body"
                          id="unityFolderUploadModalBody"
                        >
                          Are you sure you want to empty{' '}
                          <code>s3:{unityRootPath}</code> and replace it with
                          your local <code>{sourceFolderName}</code> folder?
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            id="unityFolderUploadModalNoButton"
                            data-bs-dismiss="modal"
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="unityFolderUploadModalYesButton"
                            data-bs-dismiss="modal"
                            onClick={async () => {
                              // Empty the destination s3 location
                              // await s3DeleteFolder(unityRootPath, auth0Token);
                              console.warn('TODO: WILL BE REWRITTEN');
                              // const uploadedFolder = [];
                              // for (let fileInd in Object.keys(folder)) {
                              //   const uploadParams = {
                              //     folder:
                              //       unityRootPath +
                              //       folder[fileInd].webkitRelativePath
                              //         .split('/')
                              //         .slice(0, -1)
                              //         .join('/'),
                              //     file: folder[fileInd],
                              //   };
                              //   const uploadedFile =
                              //     await s3Helper.uploadObject(uploadParams);
                              //   if (uploadedFile.params) {
                              //     console.log(uploadedFile);
                              //     uploadedFolder.push(uploadedFile.params.Key);
                              //   } else {
                              //     console.log(uploadedFile.message); // if the extension is not supported
                              //   }
                              // }
                              // console.log(uploadedFolder);
                              // setUnityFolderUploaded(uploadedFolder);
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div>
                {unityFolderUploaded.length > 0 ? (
                  <div className="form-text text-success">
                    Your folder is uploaded to <i>{unityFolderUploaded}</i>
                  </div>
                ) : (
                  <div className="form-text">
                    You can upload a folder to a path under{' '}
                    <i>s3:test_data/unity/*</i>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default E2E;
